
let Home = ()=> import('../views/Home.vue');

const basicRoutes = [
    {
        path: "/",
        name: "login",
        title: '储能智慧消防监管平台',
        redirect: '/login',
    },
    {
        path: "/home",
        name: "home",
        title: '首页',
        meta: {
            title: '首页'
        },
        redirect: '/index',
        component: Home,
        children: [
            {
                path: "/index",
                name: "index",
                title: '首页',
                meta: {
                    title: '首页'
                },
                component: ()=> import('../views/index/Index.vue'),
            },
        ]
    },
    {
        path: "/login",
        name: "login",
        title: '登录',
        component: ()=> import('../views/userCenter/Login.vue')
    },
    {
        path: "/demo",
        name: "demo",
        title: '样例',
        meta: {
            title: '样例'
        },
        component: Home,
        children: [
            {
                path: "demo1",
                name: "demo1",
                title: '样例1',
                component: ()=> import('../views/demo/demo1.vue'),
            },
        ]
    },
    {
        path: "/essStorage",
        name: "essStorage",
        title: '储能舱管理',
        meta: {
            title: '储能舱管理'
        },
        component: Home,
        children: [
            {
                path: "/energyStorage",
                name: "energyStorage",
                title: '储能舱管理',
                meta: {
                    title: '储能舱管理'
                },
                component: ()=> import('../views/essStoreMgt/EnergyStorage.vue'),
            },
        ]
    },
    {
        path: "/alermMgt",
        name: "alermMgt",
        title: '报警管理',
        meta: {
            title: '报警管理'
        },
        component: Home,
        children: [
            {
                path: "/alarmList",
                name: "alarmList",
                title: '报警管理',
                meta: {
                    title: '报警管理'
                },
                component: ()=> import('../views/alermMgt/AlarmList.vue'),
            },
        ]
    },
    {
        path: "/equipment",
        name: "equipment",
        title: '设备管理',
        meta: {
            title: '设备管理'
        },
        component: Home,
        children: [
            {
                path: "equipmentMgt",
                name: "equipmentMgt",
                title: '设备管理',
                meta: {
                    title: '设备管理'
                },
                component: ()=> import('../views/equipment/EquipmentMgt.vue'),
            },
            {
                path: "operationRecords",
                name: "operationRecords",
                title: '运行记录',
                meta: {
                    title: '运行记录'
                },
                component: ()=> import('../views/equipment/OperationRecords.vue'),
            },
        ]
    },
    {
        path: "/spareParts",
        name: "spareParts",
        title: '备件管理',
        meta: {
            title: '备件管理'
        },
        component: Home,
        children: [
            {
                path: "equipmentList",
                name: "equipmentList",
                title: '备件列表',
                meta: {
                    title: '备件列表'
                },
                component: ()=> import('../views/spareParts/EquipmentList.vue'),
            },
        ]
    },
    {
        path: "/docCenter",
        name: "docCenter",
        title: '文档中心',
        meta: {
            title: '文档中心'
        },
        component: Home,
        children: [
            {
                path: "docCenter",
                name: "docCenter",
                title: '文档中心',
                meta: {
                    title: '文档中心'
                },
                component: ()=> import('../views/docCenter/DocCenter.vue'),
            },
        ]
    },
    {
        path: "/sysSetting",
        name: "sysSetting",
        title: '系统设置',
        meta: {
            title: '系统设置'
        },
        component: Home,
        children: [
            {
                path: "userMgt",
                name: "userMgt",
                title: '用户管理',
                meta: {
                    title: '用户管理'
                },
                component: ()=> import('../views/sysSetting/UserMgt.vue'),
            },
            {
                path: "pushMgt",
                name: "pushMgt",
                title: '推送管理',
                meta: {
                    title: '推送管理'
                },
                component: ()=> import('../views/sysSetting/PushMgt.vue'),
            },
        ]
    },
    {
        path: "/userCenter",
        name: "userCenter",
        title: '个人中心',
        meta: {
            title: '个人中心'
        },
        component: Home,
        children: [
            {
                path: "user",
                name: "user",
                title: '个人中心',
                meta: {
                    title: '个人中心'
                },
                component: ()=> import('../views/userCenter/User.vue'),
            },
        ]
    },
]

export default basicRoutes;